import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {useStaticQuery, graphql} from "gatsby"
import Layout from "../components/layout";
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Img from 'gatsby-image'
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },

    root: {
        padding: theme.spacing(5, 0, 1, 1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        }
    },
    title: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2vw',
        fontWeight: 'bold',
        color: 'seashell',
        textShadow: '1px 1px 150px #080705',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vh',
            margin: '2rem 0 0 0 ',
        }
    },

    media: {
        height: 'fit-content',
        width:'100%',
        [theme.breakpoints.down('xs')]: {
            height: 250,
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    paragraphTitle: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2rem',
        fontWeight: 'bold',
        color: '#f2f2f2',
        textAlign: 'left',
        marginTop: '2rem',
        marginBottom:'2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vw',
            lineHeight: '3rem',
            marginTop: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '4vw',
            lineHeight: '1.5',
            marginTop: '0rem',
        },
    },

    link:{
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        // textDecoration: 'none',
        color: '#dce1e3',
        '&:hover': {
            color: 'goldenrod',
            '&::after': {
                content: '27F6',
                display: 'block',
                marginTop: 'auto',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '3vw',
        },
    },
    paragraph: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.5rem',
        padding: '1rem',
        // marginTop: '',
        lineHeight: '2.5rem',
        color: '#f2f2f2',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5vw',
            marginTop: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5vh',
            lineHeight: '1.2',
        },
    },

    otherContents: {
        color: '#f2f2f2',
        fontSize: '1.8rem',
        marginTop: '3rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8vh',
            lineHeight: '2rem',
        },
    },

    icon: {
        color: 'white',
        fontSize: '1.7rem',
        margin: theme.spacing(3),
        border: '1px solid',
        '&:hover': {
            color: 'goldenrod',
            background: 'rgba(0,0,0,0) 0%',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    contents:{
        width:'100%',
        margin:'1rem',
        border:'1px solid white',
        boxShadow: '0 10px 25px 0 rgb(133 191 186 / 50%)',

        [theme.breakpoints.down('sm')]: {
            margin: '0 0 1rem 0'
        },
    },
    url:{
        fontSize:'1rem',
    }
}));

export default function Schedule() {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query{
            placeholderImage: file(relativePath: {eq: "2025_0123_live.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
        }`
    );

    return (
        <Layout>
            <Container maxWidth="lg" component="main" className={classes.root}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Schedule
                </Typography>
                <Grid container className={classes.contents} spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【日時】2025.1月23日(木)開場18:00 開演19:00<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【イベント名】MichelE Party 2025<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            【会場】Jazz Dining B-flat Akasaka (〒107-0052 東京都港区赤坂６丁目６-４ 赤坂栄ビル B1F )<br/>
                            【料金】¥3,850(税込) ＋1drink,1food<br/>
                            【ご予約】<a href="https://00m.in/okPOZ">https://00m.in/okPOZ</a><br/>
                            ※座席種類などをご希望の方はこちらから→<br/>
                            <a href="https://00m.in/okPOZ">https://00m.in/vopPz</a><br/>
                            TEL 03-5563-2563<br/>
                            Aoi MichelE ワンマンライブ！ぜひ、B-flatさんの美味しいお料理と一緒にお楽しみください！
                        </Typography>
                        <Img fluid={data.placeholderImage.childImageSharp.fluid} className={classes.placeholder}/>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

